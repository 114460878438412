import gql from "graphql-tag";
import { Query } from "@deity/falcon-ecommerce-uikit";

export const GET_CATEGORY_PRODUCTS = gql`
  query CategoryProducts(
    $categoryId: String!
    $sort: SortOrderInput
    $pagination: PaginationInput
    $filters: [FilterInput]
    $path: String!
  ) {
    category(id: $categoryId) {
      name
      description
      children {
        name
        urlPath
        children {
          name
          urlPath
        }
      }
      shortDescription
      longDescription
      image
      imageLink
      urlPath
      seo {
        title
        description
      }
      landingPage
      breadcrumbs(path: $path) {
        name
        urlPath
      }
      products(sort: $sort, pagination: $pagination, filters: $filters) {
        items {
          id
          name
          brandName
          shortDescription
          promotionalBadge
          new
          price {
            regular
            special
            minTier
          }
          thumbnail
          urlPath
          attributes {
            attributeCode
            value
          }
          configurableOptions {
            values {
              label
              price {
                regular
                special
                minTier
                suggestedRetail
              }
            }
          }
        }
        pagination {
          currentPage
          totalItems
          nextPage
          perPage
        }
        priceLimits {
          min
          max
        }
        aggregations {
          field
          type
          buckets {
            value
            count
            title
          }
          title
        }
      }
    }
  }
`;

const fetchMore = (data, apolloFetchMore) =>
  apolloFetchMore({
    variables: {
      pagination: {
        perPage: data.category.products.pagination.perPage,
        page: data.category.products.pagination.nextPage
      }
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;

      return Object.assign({}, prev, {
        category: {
          ...prev.category,
          products: {
            ...prev.category.products,
            items: [
              ...prev.category.products.items,
              ...fetchMoreResult.category.products.items
            ],
            pagination: { ...fetchMoreResult.category.products.pagination }
          }
        }
      });
    }
  });

export class CategoryProductsQuery extends Query {
  static defaultProps = {
    query: GET_CATEGORY_PRODUCTS,
    fetchMore,
    notifyOnNetworkStatusChange: true
  };
}
