import React, { useState } from "react";
import cx from "classnames";

import { Button, ButtonTypes } from "../Button";

import * as styles from "./index.css";
import gwpImage from "./gwp-soldes-01-2025.jpg";
import gwpImageMobile from "./gwp-soldes-01-2025-mobile.jpg";

function GWPPopIn() {
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className={cx(styles.modal, { visible: visible })} role="alert">
        <div className={styles.cross} onClick={onClose} role="button" />
        <img
          src={gwpImageMobile}
          alt=""
          width="680"
          height="1080"
          className={styles.mobileImage}
        />
        <div className={styles.content}>
          <div className={styles.title}>Offre Soldes !</div>
          <div className={styles.description}>
            Une trousse et 2 minis produits{" "}
            <b>
              OFFERTS<sup>*</sup>
            </b>{" "}
            dès 80€ d'achat
            <br />
            <br />
            Code : <b>SOLDES25</b>
          </div>
          <Button
            text={"Je fonce"}
            type={ButtonTypes.Primary}
            as={"link"}
            href={"/soldes"}
            onClick={onClose}
            style={{
              backgroundColor: "#000",
              color: "#FFF",
              borderRadius: 0,
              marginTop: "20px",
            }}
          />
          <p style={{ fontSize: "10px", marginTop: "48px", color: "#555" }}>
            *Offre valable jusqu’au 04/02/2025 dans la limite des stocks
            disponibles. Les cadeaux peuvent varier de taille et de marque en
            fonction des disponibilités.
          </p>
        </div>
        <img
          src={gwpImage}
          alt=""
          width="320"
          style={{ marginRight: "24px" }}
        />
      </div>
      <div
        className={cx(styles.modalBackDrop, { visible: visible })}
        onClick={onClose}
      />
    </>
  );
}

export default GWPPopIn;
