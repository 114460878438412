import moment from "moment";

const frenchPublicHolidays = [
  "01-01",
  "04-21",
  "05-01",
  "05-08",
  "05-29",
  "07-14",
  "08-15",
  "11-01",
  "11-11",
  "12-25",
];

function isPublicHoliday(date: Date): boolean {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return frenchPublicHolidays.indexOf(`${month}-${day}`) !== -1;
}

function formatDeliveryDates(dates: Date[]) {
  // If only one day : "Lundi 1 Janvier"
  if (dates.length === 1) {
    return moment(dates[0]).format("dddd D MMMM");
  }

  // If multiple days on same month : "1 - 2 Janvier"
  if (dates[0].getMonth() === dates[1].getMonth()) {
    return `${moment(dates[0]).format("D")} - ${moment(dates[1]).format(
      "D MMMM"
    )}`;
  }

  // If multiple days on different months : "1 Janvier - 2 Février"
  return `${moment(dates[0]).format("D MMMM")} - ${moment(dates[1]).format(
    "D MMMM"
  )}`;
}

function getDeliveryDates(attributeSetName: string): Date[] {
  const processingDurationDays = attributeSetName === "Watch" ? [5, 13] : [0];
  const processingDays = [1, 2, 3, 4, 5];
  const shippingDays = [1, 3, 5];
  const lateProcessingHour = 11;
  const carrierWorkingDays = [1, 2, 3, 4, 5, 6];
  const carrierDurationDays = 2;

  const deliveryDates = [];

  for (const processingDuration of processingDurationDays) {
    // Calculate shipping date based on processing delay excluding public holidays and non processing days
    let shippingDate = new Date(new Date().getTime());
    let remainingProcessingDays = processingDuration;

    while (remainingProcessingDays > 0) {
      shippingDate.setDate(shippingDate.getDate() + 1);

      if (
        processingDays.indexOf(shippingDate.getDay()) !== -1 &&
        !isPublicHoliday(shippingDate)
      ) {
        remainingProcessingDays -= 1;
      }
    }

    if (
      processingDuration === 0 &&
      shippingDate.getHours() >= lateProcessingHour
    ) {
      shippingDate.setDate(shippingDate.getDate() + 1);

      while (
        processingDays.indexOf(shippingDate.getDay()) === -1 ||
        isPublicHoliday(shippingDate)
      ) {
        shippingDate.setDate(shippingDate.getDate() + 1);
      }
    }

    while (shippingDays.indexOf(shippingDate.getDay()) === -1) {
      shippingDate.setDate(shippingDate.getDate() + 1);
    }

    // // 2. Calculate delivery date based on carrier duration and carrier working days
    let durationDaysToDelivery = carrierDurationDays;
    let deliveryDate = new Date(shippingDate.getTime());

    while (durationDaysToDelivery > 0) {
      deliveryDate.setDate(deliveryDate.getDate() + 1);

      if (
        carrierWorkingDays.indexOf(deliveryDate.getDay()) != -1 &&
        !isPublicHoliday(deliveryDate)
      ) {
        durationDaysToDelivery -= 1;
      }
    }

    deliveryDates.push(deliveryDate);
  }

  return deliveryDates;
}
export { formatDeliveryDates };
export default getDeliveryDates;
