import React, { useState } from "react";
import cx from "classnames";
import * as styles from "./index.css";
// import { Button, ButtonTypes } from "../Button";

const endDate = new Date("2025-01-01T00:00:00+01:00");

function SalePopIn() {
  const [visible, setVisible] = useState(true);
  const now = new Date();

  if (now > endDate) {
    return null;
  }

  const onClose = () => {
    setVisible(false);
  };

  // const onClickLink = () => {
  //   onClose();
  // };

  return (
    <>
      <div className={cx(styles.modal, { visible: visible })} role="alert">
        <div className={styles.cross} onClick={onClose} role="button" />
        <div className={styles.background} />
        {/* <Button
          text="J'en profite !"
          as="link"
          type={ButtonTypes.Primary}
          href={"/soldes"}
          onClick={onClickLink}
          style={{ backgroundColor: "#FFF", color: "#000" }}
        /> */}
      </div>
      <div
        className={cx(styles.modalBackDrop, { visible: visible })}
        onClick={onClose}
      />
    </>
  );
}

export default SalePopIn;
